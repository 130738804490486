/* Todo list */

.add-items {
  margin-bottom: 1.5rem;
  overflow: hidden;

  input[type="text"] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
    background: transparent;
  }

  .btn {
    margin-left: .5rem;

    .rtl & {
      margin-left: auto;
      margin-right: .5rem;
    }
  }
}

.list-wrapper {
  max-height: 100%;

  ul {
    padding: 0;
    text-align: left;
    list-style: none;
    margin-bottom: 0;

    li {
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-start;
      font-size: .9375rem;
      padding: .4rem 0;
      border-bottom: 1px solid $border-color;

      &:last-child {
        border-bottom: none;
      }

      .form-check {
        @extend %ellipsor;
        max-width: 90%;
        margin-top: .25rem;
        margin-bottom: .25rem;

        .form-check-label {
          @extend %ellipsor;

          input {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    &.todo-padding-lg {
      li {
        padding: 1.25rem 0;
      }
    }

    &.todo-list {
      &.rtl-todo {
        display: none;
      }

      .rtl & {
        display: none;

        &.rtl-todo {
          display: block;
        }
      }
    }
  }

  input[type="checkbox"] {
    margin-right: 15px;
  }

  .remove {
    @extend .ml-auto;
    @extend .text-secondary;

    .rtl & {
      @extend .ml-0;
      @extend .mr-auto;
    }

    cursor: pointer;
    font-size: 1.438rem;
    font-weight: 600;
    width: 1.25rem;
    height: 1.25rem;
    line-height: 20px;
    text-align: center;
  }

  .completed {
    text-decoration: line-through;
    text-decoration-color: theme-color(info);

    .remove {
      @extend .text-primary;
    }
  }
}

