// Wizard

.form-wizard {
  .container {
    @extend .px-0;

    ol {
      &.progtrckr {
        @include display-flex;
        @include justify-content(space-between);
        @include flex-wrap(wrap);
        padding-left: 0;
        list-style-type: none;
        @media(max-width: 767px) {
          @include justify-content(normal);
        }

        li {
          background: #e6e9ed;
          @extend .col;
          text-align: center;
          padding: 1em 1em;
          margin: 0 0.5em 0.5em;
          border-radius: 5px;
          font-weight: 600;
          @media(max-width: 767px) {
            width: auto;
            &:first-child {
              margin-left: auto;
            }
          }

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }

          em {
            margin-right: 1px;
            font-style: inherit;
          }

          &.progtrckr-doing {
            background: theme-color(primary);
            color: $white;
          }

          &.progtrckr-done {
            background: rgba(theme-color(primary), .3);
            color: $white;
          }
        }
      }
    }

    .content {
      min-height: 25rem;
      background: rgba(theme-color(primary), .1);
      padding: 2rem;
      border-radius: 5px;
      overflow: scroll;
    }

    > div {
      &:not(.content) {
        margin-top: 20px;
        text-align: right;
        @media(max-width: 767px) {
          text-align: center;
        }

        > button {
          @extend .btn;
          @extend .btn-primary;

          + button {
            margin-left: 10px;
            @media(max-width: 767px) {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  &.form-wizard-vertical {
    .container {
      @extend .row;
      @extend .m-0;

      ol {
        display: block;

        &.progtrckr {
          @extend .col-md-3;
          @extend .pl-0;

          li {
            margin: 0.5em 0 0.5em 0;

            &:first-child {
              margin-top: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .content {
        @extend .col-md-9;
      }

      > div {
        &:not(.content) {
          @extend .col-md-12;
          @extend .px-0;
        }
      }
    }
  }
}