
.react-images__positioner {
  &.css-1rbq2qy {
    &.css-1ycyyax {
      z-index: 9999;
    }
  }
}

.react-images__blanket {
  &.css-yrspe {
    &.css-1ycyyax {
      z-index: 9999;
    }
  }
}