.PoliticaPrivacidade {
  padding: 20px 0;

  h1 {
    margin: 1.81rem;
    margin-bottom: 0;

    @media (max-width: 767px) {
      margin: 1rem;
      margin-bottom: 0;
    }
  }

  > div {
    max-width: 1000px;
    margin: auto;
  }

  p {
    text-align: justify;

    br {
      margin-bottom: 10px;
      display: block;
      content: "";
    }
  }

  h3 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 30px;
  }

}