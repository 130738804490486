.operation-card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #665A4F;

  p {
    color: #FFF;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    line-height: 1.2;
    margin-top: 4px;
    margin-bottom: 0;
  }
}

.clinic-info-card {
  display: flex;
  flex-direction: column;
  padding: 10px 1.81rem 1.88rem 1.81rem;

  p {
    strong {
      font-size: 11px;
      text-transform: uppercase;
    }

    color: #A7AFB7;
    margin: 0;
  }

  i {
    align-self: flex-end;
  }

  .details {
    color: #A7AFB7;
  }
}

.btn.outlined {
  border-color: #BFCCD9;
  color: #001737;
}

.underline {
  text-decoration: underline;
}

.primary-color {
  color: #001737;
}

.secondary-color {
  color: #A7AFB7;
}

.badge-points {
  padding: 6px;
  font-weight: normal;
  font-size: 14px;

  &.badge-inverse-dark {
    background-color: #D9D6D3;
  }
}

.card-padding {
  padding: 1.81rem;

  @media (max-width: 767px) {
    padding: 1.81rem 1rem;
  }
}

.carousel-news-title {
  margin: 0;
  font-weight: bold;
  font-size: 18px;
}

.carousel-news-details {
  flex-direction: column;

  .carousel-news-date {
    font-size: 12px;
  }
}

.carousel-news-all {
  text-decoration: underline;
  font-size: 13px;
}

.badge-redeem-points {
  width: 12px;
  height: 12px;
}

.table-title {
  font-size: 16px;
}

.buttons-table {
  label {
    margin-bottom: 0;
    flex: 1;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: stretch;

    input, button {
      margin: 0 !important;
      margin-bottom: 10px !important;
    }

    button {
      width: 100%;
    }

    .range {
      span {
        width: 100%;
        display: flex;

        > div:not(.react-datepicker__tab-loop) {
          flex: 1;

          input {
            max-width: 60vw;
            margin-left: auto !important;
          }
        }
      }
    }
  }

}

.teste {
  font-weight: 700;
}

.modal-90w {
  width: 90%;
}

.badge-icon {
  position: relative;
  top: -1px;
}

.header-container {
  align-items: flex-start;
  justify-content: space-between;
  width: 90%;
  max-width: 1100px;
  min-height: 100%;
  margin: auto;
  background-repeat: no-repeat;
  background-position: right 12% center;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.header-panels {
  align-self: stretch;
  display: flex;
  justify-content: space-between;

  &.responsive {
    @media (max-width: 767px) {
      flex-direction: column-reverse;
      align-items: flex-end;

      > div:first-child {
        overflow: auto;
        white-space: nowrap;
        padding: 0 5%;
        width: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .table-responsive {
    white-space: nowrap;
  }
}

.header-panel {
  padding: 10px 20px;
  background-color: rgba(243, 244, 250, 0.5);
  color: #001737;
  font-weight: bold;
}

.page-item {
  button {
    border-radius: 0;
    background-color: white;
    color: #001737;
  }

  &:first-child {
    button {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
  }

  &:last-child {
    button {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }

  .active {
    background-color: #A7AFB7;
    color: white;
  }
}

.react-datepicker__tab-loop {
  display: inline-block;
}

.react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day {
  padding: 0;
}